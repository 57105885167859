<template>
	<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
		<div class="pt-2 p-l-5">
			<span>{{ $t('조회기간') }}</span>
		</div>
		<div class="p-l-5">
			<div class="d-flex flex-row">
				<div>
					<date-picker placeholder="Start Date" :value="searchBoxCond.datePicker.fromDate"
						:style="searchBoxCond.conditions.timeDsvn == '' ? { width: '135px' } : { width: '100px' }"
						:config="{ format: this.fromFormat, viewMode: this.searchBoxCond.datePicker.viewMode, locale: locale, ignoreReadonly: true }"
						:disabled="['Min', 'Quarter1'].includes(searchBoxCond.conditions.timeDsvn)"
						:readonly="['Min', 'Quarter1'].includes(searchBoxCond.conditions.timeDsvn)"
						@dp-hide="onFromDatePicker($event.target.value)" />
				</div>
				<div class="pt-2 p-l-5 p-r-5">
					<span> ~ </span>
				</div>
				<div class="p-r-5">
					<date-picker placeholder="End Date" readonly="readonly" :value="searchBoxCond.datePicker.toDate"
						:style="searchBoxCond.conditions.timeDsvn == '' ? { width: '135px' } : { width: '100px' }"
						:config="{ format: this.toFormat, viewMode: this.searchBoxCond.datePicker.viewMode, locale: locale, ignoreReadonly: true }"
						@dp-hide="onToDatePicker($event.target.value)" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import SearchUtils from "../SearchUtils.js";

export default {
	name: "Type1DatePicker",
	components: {},
	props: ["searchBoxCond"],
	watch: {},
	data() {
		return {
			config: {},
		};
	},
	computed: {
		locale() {
			return this.$store.state.locale === 'kr' ? 'ko' : this.$store.state.locale;
		},
		fromFormat: {
			get: function () {
				return this.searchBoxCond.datePicker.fromFormatter;
			},
			set: function (newValue) {
				this.searchBoxCond.datePicker.fromFormatter = newValue;
				
			},
		},
		toFormat: {
			get: function () {
				return this.searchBoxCond.datePicker.toFormatter;
			},
			set: function (newValue) {
				this.searchBoxCond.datePicker.toFormatter = newValue;
			
			},
		},
	},
	created() {
		// if (this.searchBoxCond.conditions.timeDsvn === "Hour") {
		if (['Min', 'Quarter'].includes(this.searchBoxCond.conditions.timeDsvn)) {
			this.searchBoxCond.datePicker.toDate = this.searchBoxCond.datePicker.fromDate;
		}
	},
	mounted() { },
	destroyed() { },

	methods: {
		calcDate(timeDsvn, date) {
			let result = null;

			switch (timeDsvn) {
				case "Min":
					result = new Date(date.getFullYear(), date.getMonth(), date.getDate());
					break;
				case "Quarter":
					result = new Date(date.getFullYear(), date.getMonth(), date.getDate());
					break;
				case "Hour":
					result = new Date(date.getFullYear(), date.getMonth(), date.getDate());
					break;
				case "Day":
					result = new Date(date.getFullYear(), date.getMonth(), date.getDate());
					break;
				case "Week":
					result = new Date(date.getFullYear(), date.getMonth(), 1);
					break;
				case "Month":
					result = new Date(date.getFullYear(), date.getMonth(), 1);
					break;
				case "Year":
					result = new Date(date.getFullYear(), date.getMonth(), 1);
					break;
				default:
					result = new Date(date.getFullYear(), date.getMonth(), date.getDate());
					break;
			}

			return result;
		},

		calcCompareDate() {
			console.log("searchBoxCond in calcCompareDate",this.searchBoxCond);
			let yy = 0,
				mm = 0,
				dd = 0;
			let fromDate = new Date(this.searchBoxCond.datePicker.fromDate);
			let toDate = new Date(this.searchBoxCond.datePicker.toDate);

			if (this.searchBoxCond.conditions.compareType === "previousDay") dd = 1;
			else if (this.searchBoxCond.conditions.compareType === "previousMonth") mm = 1;
			else if (this.searchBoxCond.conditions.compareType === "previousYear") yy = 1;

			this.searchBoxCond.datePicker.compFromDate = new Date(fromDate.getFullYear() - yy, fromDate.getMonth() - mm, fromDate.getDate() - dd);
			this.searchBoxCond.datePicker.compToDate = new Date(toDate.getFullYear() - yy, toDate.getMonth() - mm, toDate.getDate() - dd);
		},

		onFromDatePicker(value) {
			//@dp-change로 이벤트 동작 시 무한 루프
			// let date = new Date(this.searchBoxCond.datePicker.fromDate);
			let date = new Date(value);
			let now = new Date();

			date = (date > now) ? now : date;

			this.searchBoxCond.datePicker.fromDate = this.calcDate(this.searchBoxCond.conditions.timeDsvn, date);

			// timeDsvn(Log/Min)의 경우, toDate를 동일하게 설정해야 함.
			if (['Log', 'Min'].includes(this.searchBoxCond.conditions.timeDsvn)) {
				this.searchBoxCond.datePicker.toDate = this.searchBoxCond.datePicker.fromDate
			} else {
				// fromDate > toDate인 경우, fromDate를 동일하게 설정해야 함.
				if (this.searchBoxCond.datePicker.fromDate > this.searchBoxCond.datePicker.toDate) {
					this.searchBoxCond.datePicker.toDate = this.searchBoxCond.datePicker.fromDate;
				}
			}

			//조회 기간에 대한 사용자 입력에 따라 비교날짜를 새로 계산하기 위함.
			this.calcCompareDate();
			// fromDate > toDate인 경우 처리
			this.searchBoxCond.debug && console.log("searchBoxCond.datePicker.fromDate: ", this.searchBoxCond.datePicker.fromDate);
			this.searchBoxCond.debug && console.log("searchBoxCond.datePicker.compFromDate: ", this.searchBoxCond.datePicker.compFromDate);
		},

		onToDatePicker(value) {
			//@dp-change로 이벤트 동작 시 무한 루프
			// let date = new Date(this.searchBoxCond.datePicker.fromDate);
			let date = new Date(value);
			let now = new Date();

			date = (date > now) ? now : date;

			this.searchBoxCond.datePicker.toDate = this.calcDate(this.searchBoxCond.conditions.timeDsv, date);

			// timeDsvn(Log/Min)의 경우, fromDate를 동일하게 설정해야 함.
			if (['Log', 'Min'].includes(this.searchBoxCond.conditions.timeDsvn)) {
				this.searchBoxCond.datePicker.fromDate = this.searchBoxCond.datePicker.toDate;
			} else {
				// fromDate > toDate인 경우, fromDate를 동일하게 설정해야 함.
				if (this.searchBoxCond.datePicker.fromDate > this.searchBoxCond.datePicker.toDate) {
					this.searchBoxCond.datePicker.fromDate = this.searchBoxCond.datePicker.toDate;
				}
			}

			// if (this.searchBoxCond.conditions.timeDsvn === "Week") {
			// 	this.searchBoxCond.datePicker.fromDate = new Date(this.searchBoxCond.datePicker.fromDate.getFullYear(), this.searchBoxCond.datePicker.fromDate.getMonth(), 1);
			// 	this.searchBoxCond.datePicker.toDate = new Date(this.searchBoxCond.datePicker.toDate.getFullYear(), this.searchBoxCond.datePicker.toDate.getMonth() + 1, 0);
			// }
			
			if (this.searchBoxCond.conditions.timeDsvn === "Month") {
				let year = this.searchBoxCond.datePicker.toDate.getFullYear();
				let month = this.searchBoxCond.datePicker.toDate.getMonth();
				this.searchBoxCond.datePicker.toDate = new Date(year, month + 1, 0);
			}

			//조회 기간에 대한 사용자 입력에 따라 비교날짜를 새로 계산하기 위함.
			this.calcCompareDate();
			this.searchBoxCond.debug && console.log("searchBoxCond.datePicker.toDate: ", this.searchBoxCond.datePicker.toDate);
			this.searchBoxCond.debug && console.log("searchBoxCond.datePicker.compToDate: ", this.searchBoxCond.datePicker.compToDate);
		},

		setDateFormatter() {
			switch (this.searchBoxCond.conditions.timeDsvn) {
				case "Min": // 분단위 집계
					this.fromFormat = "YYYY-MM-DD";
					this.toFormat = "YYYY-MM-DD";
					this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMMdd";
					this.searchBoxCond.datePicker.paramToFormatter = "yyyyMMdd";
					break;

				case "Quarter": // 15Min Sum
					this.fromFormat = "YYYY-MM-DD";
					this.toFormat = "YYYY-MM-DD";
					this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMMdd";
					this.searchBoxCond.datePicker.paramToFormatter = "yyyyMMdd";
					break;

				case "Hour": // Hour Sum
					this.fromFormat = "YYYY-MM-DD";
					this.toFormat = "YYYY-MM-DD";
					this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMMdd";
					this.searchBoxCond.datePicker.paramToFormatter = "yyyyMMdd";
					break;

				case "Week": // Weekly Sum
					this.fromFormat = "YYYY-MM";
					this.toFormat = "YYYY-MM";
					this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMMdd";
					this.searchBoxCond.datePicker.paramToFormatter = "yyyyMMdd";
					break;

				case "Day": // Day Sum
					this.fromFormat = "YYYY-MM-DD";
					this.toFormat = "YYYY-MM-DD";
					this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMMdd";
					this.searchBoxCond.datePicker.paramToFormatter = "yyyyMMdd";
					break;

				case "Month": // Month Sum
					this.fromFormat = "YYYY-MM";
					this.toFormat = "YYYY-MM";
					this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMM";
					this.searchBoxCond.datePicker.paramToFormatter = "yyyyMM";
					break;

				case "Year": // Year Sum
					this.fromFormat = "YYYY";
					this.toFormat = "YYYY";
					this.searchBoxCond.datePicker.paramFromFormatter = "yyyy";
					this.searchBoxCond.datePicker.paramToFormatter = "yyyy";
					break;

				default:
					this.fromFormat = "YYYY-MM-DD";
					this.toFormat = "YYYY-MM-DD";
					this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMMdd000";
					this.searchBoxCond.datePicker.paramToFormatter = "yyyyMMdd2359";
					break;
			}
		},
	},
};
</script>

<style scoped>
.form-control[readonly] {
	background: #fff;
	color: #2d353c;
	opacity: 1;
}
</style>
