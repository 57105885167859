<template>
	<div>
		<div class="row p-10">
			<!-- 원단위항목 -->
			<!-- <org-unit-dvsn-code-combo-box :ref="'orgUnitDvsnCodeComboBox'" @ready:data="onInitComplete" /> -->
			<!-- 조회조건, 조회기간, 비교대상 -->
			<search-condition-type-box :ref="'searchConditionBox'" :searchBoxCond="searchBoxCond" :searchOptions="'Hour,Day,Month,Year'" />
			<!-- 조회버튼 -->
			<search-btn :ref="'searchBtn'" @search="onSearch" />
		</div>
	</div>
</template>

<script>
	import backEndApi from "@src/api/backEndApi";

	// import orgUnitDvsnCodeComboBox from "../../component/comboBox/analysis/OrgUnitDvsnCodeComboBox.vue";
	import searchConditionTypeBox from "../../component/searchBox/analysis/type1/Type1Box.vue";
	import searchBtn from "../../component/searchBox/analysis/SearchBtn.vue";

	import { StatusCodes } from "http-status-codes";
	import AppErrorHandler from "@src/appErrorHandler";	
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		components: {
			// orgUnitDvsnCodeComboBox,
			searchConditionTypeBox,
			searchBtn,
		},
		props: ["searchBoxCond", "orgUnitDvsnCode"],
		watch: {
			async orgUnitDvsnCode() {
				console.log("changedddddd");
				console.log(this.orgUnitDvsnCode);
        await this.onSearch();
			},
		},
		data() {
			return {
				waitLoads: 0,
				popupMessages
			};
		},
		created() {},
		async mounted() {
			this.waitLoads = 0;
			await this.onSearch();
		},

	methods: {
		// Initial Searching.
		async onInitComplete(data) {
			this.searchBoxCond.debug && console.debug(`onInitComplete() Initial data loading start.(data:${data}, waits:${--this.waitLoads})`);
			if (this.waitLoads <= 0) await this.onSearch();
		},

		// Start Backend API Call
		async onSearch() {
			console.log(this.orgUnitDvsnCode);
			try {
				let searchResult = null;
				let param = null;

				param = {
					timeDsvn: this.searchBoxCond.conditions.timeDsvn,
					fromDate: this.searchBoxCond.datePicker.fromDate.format(this.searchBoxCond.datePicker.paramFromFormatter),
					toDate: this.searchBoxCond.datePicker.toDate.format(this.searchBoxCond.datePicker.paramToFormatter),
					// orgUnitDvsnCode: this.$refs.orgUnitDvsnCodeComboBox.getValue(),
					orgUnitDvsnCode: this.orgUnitDvsnCode,
				};

				this.searchBoxCond.debug && console.debug("Search Params(base): ", param);

				searchResult = {
					options: this.searchBoxCond,
					data: { header:null, baseData: null, compData: null },
				};

				if (!this.$refs.searchConditionBox.checkDateRange(param)) {
					return;
				}

				// Query Base Data
				try {
					const result = await backEndApi.orgUnit.itemAnalsis(param);		
					console.log("result.data in orgunitsearch", result.data)
					// searchResult = {
					// 	options: this.searchBoxCond,
					// 	data: result.data,
					// };
					searchResult = {
						...searchResult,
						data: { ...result.data,}
						,};
					// searchResult.data = result.data;

					// searchResult = {
					// 	...searchResult,
					// 	data: {
					// 		...searchResult.data,
					// 	baseData: {...result.data}, // 변경할 새로운 header 값
					// 		},
					// };
					console.log("searchResult in onSearch func", searchResult)

				} catch (err) {
					new AppErrorHandler(err)					
						.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
						.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
						.createBizHandle(this.ERR_KIND.MISSING_DATA, popupMessages.COMMON_ANALYSIS_NO_DATA_POPUP_MESSAGE, this.alertNoti)					
						.errHandling(true);
					
				}

				//Query Compare Data
				if (this.searchBoxCond.conditions.compareType !== "none") {
					try {
						param.fromDate = this.searchBoxCond.datePicker.compFromDate.format(this.searchBoxCond.datePicker.paramFromFormatter);
						param.toDate = this.searchBoxCond.datePicker.compToDate.format(this.searchBoxCond.datePicker.paramToFormatter);
						this.searchBoxCond.debug && console.log("Search Params(comp): ", param);

						const resultComp = await backEndApi.orgUnit.itemAnalsis(param);
						console.log('resultComp if compareType is not none',resultComp)
						// searchResult.data.compData = resultComp.data.baseData;
						searchResult = {
							...searchResult,
							data: {
								...searchResult.data,
								compData: [...resultComp.data.baseData],
							},
						};
					// 	searchResult = {
					// 	...searchResult,
					// 	data: {
					// 		...searchResult.data,
					// 		compData: {...resultComp.data}, // 변경할 새로운 header 값
					// 		},
					// };
					console.log('searchResult if compareType is not none',searchResult)

						this.searchBoxCond.debug && console.log("Search Result: ", searchResult);
					} catch (compError) {
						new AppErrorHandler(compError)
							.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
							.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
							.createBizHandle(this.ERR_KIND.MISSING_DATA, popupMessages.COMMON_ANALYSIS_COMPDATA_NO_DATA_POPUP_MESSAGE, await this.alertNoti)
							.errHandling();
						searchResult.data.compData = null;
					}
				}
				console.log('final searchResult ',searchResult)
				this.$emit("submit:data", searchResult);

			} catch (e) {
				console.error("UseTargetSearch Error : ", e);
			}
		},
	},
};
</script>

<style scoped>
	.compare {
		margin-top: -5px;
		margin-bottom: 0px;
	}

	.material-switch > input[type="checkbox"] {
		display: none;
	}

	.material-switch > label {
		cursor: pointer;
		height: 0px;
		position: relative;
		width: 40px;
	}

	.material-switch > label::before {
		background: rgb(0, 0, 0);
		box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
		border-radius: 8px;
		content: "";
		height: 16px;
		margin-top: -8px;
		position: absolute;
		opacity: 0.3;
		transition: all 0.4s ease-in-out;
		width: 40px;
	}
	.material-switch > label::after {
		background: rgb(255, 255, 255);
		border-radius: 16px;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
		content: "";
		height: 24px;
		left: -4px;
		margin-top: -8px;
		position: absolute;
		top: -4px;
		transition: all 0.3s ease-in-out;
		width: 24px;
	}
	.material-switch > input[type="checkbox"]:checked + label::before {
		background: inherit;
		opacity: 0.5;
	}
	.material-switch > input[type="checkbox"]:checked + label::after {
		background: inherit;
		left: 20px;
	}
</style>
