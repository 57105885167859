<template>
  <!-- 구역구분 -->
  <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
    <div class="pt-2 p-l-5">
      <span>{{$t('구역구분')}}</span>
    </div>
    <div class="p-l-5" v-if="isSelectBox1">
      <select class="form-control" :ref="'zoneIdx1'" @change="getZonechildrenList(2, $event.target.value)">
        <option v-for="(resultData, index) in getZoneList1" :key="index" :value="resultData.zoneIdx">
          {{ resultData.zoneName }}
        </option>
      </select>
    </div>
    <!-- <div class="p-l-5" v-if="isSelectBox2 && getZoneList2.length > 0">
      <select class="form-control" :ref="'zoneIdx2'" @change="getZonechildrenList(3, $event.target.value)">
        <option value="">선택</option>
        <option v-for="(resultData, index) in getZoneList2" :key="index" :value="resultData.zoneIdx">
          {{ resultData.zoneName }}
        </option>
      </select>
    </div>
    <div class="p-l-5" v-if="isSelectBox3">
      <select class="form-control" :ref="'zoneIdx3'" @change="getZonechildrenList(4, $event.target.value)">
        <option value="">선택</option>
        <option v-for="(resultData, index) in getZoneList3" :key="index" :value="resultData.zoneIdx">
          {{ resultData.zoneName }}
        </option>
      </select>
    </div>
    <div class="p-l-5" v-if="isSelectBox4">
      <select class="form-control" :ref="'zoneIdx4'" @change="getZonechildrenList(5, $event.target.value)">
        <option value="">선택</option>
        <option v-for="(resultData, index) in getZoneList4" :key="index" :value="resultData.zoneIdx">
          {{ resultData.zoneName }}
        </option>
      </select>
    </div>
    <div class="p-l-5" v-if="isSelectBox5">
      <select class="form-control" :ref="'zoneIdx5'" @change="getZonechildrenList(6, $event.target.value)">
        <option value="">선택</option>
        <option v-for="(resultData, index) in getZoneList5" :key="index" :value="resultData.zoneIdx">
          {{ resultData.zoneName }}
        </option>
      </select>
    </div>
    <div class="p-l-5" v-if="isSelectBox6">
      <select class="form-control" :ref="'zoneIdx6'" @change="getZonechildrenList(7, $event.target.value)">
        <option value="">선택</option>
        <option v-for="(resultData, index) in getZoneList6" :key="index" :value="resultData.zoneIdx">
          {{ resultData.zoneName }}
        </option>
      </select>
    </div> -->
    <div class="p-r-5"></div>
  </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi.js";
import AppErrorHandler from "@src/appErrorHandler";				

export default {
  components: {},
  props: [],
  watch: {},
  data() {
    return {
      isSelectBox1: true,
      isSelectBox2: false,
      isSelectBox3: false,
      isSelectBox4: false,
      isSelectBox5: false,
      isSelectBox6: false,

      getZoneList1: null,
      getZoneList2: null,
      getZoneList3: null,
      getZoneList4: null,
      getZoneList5: null,
      getZoneList6: null,

      zoneIdx: 1,
    };
  },
  computed: {},
  created() { },
  async mounted() {
    await this.loadData();
  },
  updated() { },
  destroyed() { },
  methods: {
    async loadData() {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.zoneInfo.getZoneSelectBox();
        this.getZoneList1 = result.data;
      } catch (err) {
        throw err;
      } finally {
        this.$emit('ready:data', this.getValue());
      }
    },
    getValue() {
      const zoneIdx = this.getZoneIdx();
      console.log('getValue:', zoneIdx);
      return zoneIdx;
    },
    getZoneIdx() {
      // 선택 된 zoneIdx가 공백일 경우
      if (!this.zoneIdx) {
        if (this.isSelectBox6) {
          this.zoneIdx = this.$refs.zoneIdx5.value;
        } else if (this.isSelectBox5) {
          this.zoneIdx = this.$refs.zoneIdx4.value;
        } else if (this.isSelectBox4) {
          this.zoneIdx = this.$refs.zoneIdx3.value;
        } else if (this.isSelectBox3) {
          this.zoneIdx = this.$refs.zoneIdx2.value;
        } else if (this.isSelectBox2) {
          this.zoneIdx = this.$refs.zoneIdx1.value;
        }
      }
      return this.zoneIdx;
    },
    async getZonechildrenList(comboNo, zoneIdx) {
      if (comboNo == 7) {
        this.$emit("ready:data", this.getValue());
        return;
      }

      if (zoneIdx == 1) {
        this.zonechildrenList = null;
        this.isSelectBox2 = false;
        this.isSelectBox3 = false;
        this.isSelectBox4 = false;
        this.isSelectBox5 = false;
        this.isSelectBox6 = false;

        this.getZoneList2 = null;
        this.getZoneList3 = null;
        this.getZoneList4 = null;
        this.getZoneList5 = null;
        this.getZoneList6 = null;

        this.zoneIdx = zoneIdx;

        this.$emit("ready:data", this.getValue());

        return;
      } else {

        // 이전 리비전과 소스 에서 아래와 같이 변경함.
        /*           
          ↓↓↓↓↓
        */
        try {
          const result = await backEndApi.zoneInfo.getZonechildrenList(zoneIdx);

          if (comboNo == 2) {
            this.isSelectBox2 = true;
            this.getZoneList2 = result.data;
            // this.zoneIdx = this.getZoneList2[0].zoneIdx;
            this.zoneIdx = 1;
            this.getZonechildrenList(3, this.zoneIdx);
          } else if (comboNo == 3) {
            this.isSelectBox3 = true;
            this.getZoneList3 = result.data;
            // this.zoneIdx = this.getZoneList3[0].zoneIdx;
            this.zoneIdx = 1;

            this.getZonechildrenList(4, this.zoneIdx);
          } else if (comboNo == 4) {
            this.isSelectBox4 = true;
            this.getZoneList4 = result.data;
            // this.zoneIdx = this.getZoneList4[0].zoneIdx;
            this.zoneIdx = 1;
            this.getZonechildrenList(5, this.zoneIdx);
          } else if (comboNo == 5) {
            this.isSelectBox5 = true;
            this.getZoneList5 = result.data;
            // this.zoneIdx = this.getZoneList5[0].zoneIdx;
            this.zoneIdx = 1;
            this.getZonechildrenList(6, this.zoneIdx);
          } else if (comboNo == 6) {
            this.isSelectBox6 = true;
            this.getZoneList6 = result.data;
            // this.zoneIdx = this.getZoneList6[0].zoneIdx;
            this.zoneIdx =1;
            this.getZonechildrenList(7, this.zoneIdx);
          }          
        } catch (err) {

          if (comboNo == 2) {
            this.isSelectBox2 = false;
            this.isSelectBox3 = false;
            this.isSelectBox4 = false;
            this.isSelectBox5 = false;
            this.isSelectBox6 = false;
          } else if (comboNo == 3) {
            this.isSelectBox3 = false;
            this.isSelectBox4 = false;
            this.isSelectBox5 = false;
            this.isSelectBox6 = false;
          } else if (comboNo == 4) {
            this.isSelectBox4 = false;
            this.isSelectBox5 = false;
            this.isSelectBox6 = false;
          } else if (comboNo == 5) {
            this.isSelectBox5 = false;
            this.isSelectBox6 = false;
          } else if (comboNo == 6) {
            this.isSelectBox6 = false;
          }          

          new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
        } finally {
          this.zoneIdx = zoneIdx;
        }
      }

      this.$emit("ready:data", this.getValue());
    },
  },
};
</script>

<style scoped></style>
