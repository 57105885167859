<template>
	<div class="row chartArea">
		<div class="col-xl-12 col-lg-6 innerBox" :style="{ padding: '5px' }">
			<div class="col-xl-12 mixChart">
				<org-unit-chart ref="chart" v-if="orgUnitChartOpt.flag" :chartData="orgUnitChartOpt.data"
					:options="orgUnitChartOpt.options" :style="{ height: '100%', padding: '15px' }" />
			</div>
		</div>
	</div>
</template>

<script>
import AnalysisLib from "../AnalysisLib";

import OrgUnitChart from "@components/vue-chartjs/Charts/MixChart.js";

// // eslint-disable-next-line no-unused-vars

export default {
	props: ["searchResult"],
	components: {
		OrgUnitChart,
	},
	data() {
		return {
			orgUnitChartOpt: {},
			
		};
	},
	watch: {
		searchResult() {
			this.orgUnitChartOpt = {};
			this.init();
		},
	},
	created() {
		this.init();
		
	},
	mounted() { 
	},
	updated() { },

	methods: {
		init() {
			try {
				this.initChart();
				this.orgUnitChartOpt.flag = true;
				this.setChartOptions();
				this.setChartLabels();
				this.setChartDatasets();
			} catch (err) {
				console.error('Init Error : ', err);
			}
		},

		initChart() {
			this.orgUnitChartOpt = {
				flag: false,
				data: {
					labels: [],
					otherDateLabels:[],
					datasets: null,
				},
				options: null,
			};
		},

		setChartOptions() {
			this.orgUnitChartOpt.options = {
				responsive: true,
				maintainAspectRatio: false,
				animation: { duration: 1000, easing: "easeOutQuart" },
				hoverMode: "index",
				legend: { display: true, align: "end", labels: { usePointStyle: true } },
				title: {
					display: true,
					fontSize: 20,
					fontColor: "#000000",
				},
				tooltips: {
					callbacks: {
						title: (tooltipItems) => {
							const tooltipItem = tooltipItems[0];
							const datasetIndex = tooltipItem.datasetIndex;
							const index = tooltipItem.index;

							if (datasetIndex === 1 && this.orgUnitChartOpt.data.otherDateLabels.length > 0) {
								return this.orgUnitChartOpt.data.otherDateLabels[index]||""
							}

							// 기본적으로 현재 달의 날짜를 반환합니다.
							return this.orgUnitChartOpt.data.labels[index] || "";
						},
					},
				},
				hover: {
					intersect: false,
				},
				onClick: (a, b) => {
			
					// chartValue(b[0]._index)
					// console.log($store);

					let index = null;
					if (b !== undefined && b.length > 0)
						index = b[0]._index;

					this.$store.commit("SET_CHART_SELECT_VALUE", index);
					// chartValue = ;
					// char
					// console.log(chartValue, this);
				},
				scales: {
					xAxes: [
						{
							id: "x-axis-0",
							stacked: false,
							ticks: { autoSkip: true, fontColor: "#000000", fontSize: 14 },
						},
					],
					yAxes: [
						{
							id: "Left_yAxis",
							display: true,
							position: "left",
							stacked: false,
							ticks: { beginAtZero: true, fontColor: "#000000", fontSize: 14 },
						},
					],
				},
			};

		},

		setChartLabels() {
			this.orgUnitChartOpt.data.labels = [];
			// const regex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
			this.searchResult.data.baseData.forEach((data) => {
				this.orgUnitChartOpt.data.labels.push(global.xe.$t(AnalysisLib.formatDatetime(data.regDt)));
			});

			this.orgUnitChartOpt.data.otherDateLabels= [];
			this.searchResult.data.compData&&this.searchResult.data.compData.forEach((data) => {
				this.orgUnitChartOpt.data.otherDateLabels.push(global.xe.$t(AnalysisLib.formatDatetime(data.regDt)));
			});
		},

		setChartDatasets() {
			let chartIdx = 0;

			this.orgUnitChartOpt.data.datasets = [];

			this.searchResult.data.header.filter(header => header.dispOptions.chart === true).forEach((header) => {

				// Base Dataset
				if (this.searchResult.data.baseData.length > 0) {
					chartIdx = this.addChartDatasets("base");
					this.orgUnitChartOpt.data.datasets[chartIdx - 1].label = header.divCdName;
					this.setChartColor(this.orgUnitChartOpt.data.datasets[chartIdx - 1], "base", header.divCd);
					this.setChartDatasetsToData(this.orgUnitChartOpt.data.datasets[chartIdx - 1], "base", header.divCd);
				}

				// Compare Dataset
				if (this.searchResult.data.compData && this.searchResult.data.compData.length > 0) {
					chartIdx = this.addChartDatasets("compare");
					this.orgUnitChartOpt.data.datasets[chartIdx - 1].label = header.divCdName + " " + global.xe.$t("비교");
					this.setChartColor(this.orgUnitChartOpt.data.datasets[chartIdx - 1], "compare", header.divCd);
					this.setChartDatasetsToData(this.orgUnitChartOpt.data.datasets[chartIdx - 1], "compare", header.divCd);
				}
			});

		},

		setChartColor(datasets, key, divCd) {
			if (key === "base") {
				datasets.backgroundColor = AnalysisLib.getChartBaseColor(this.searchResult.options.viewType, divCd);
				datasets.pointBackgroundColor = AnalysisLib.getItemColor(this.searchResult.options.viewType, divCd);
				datasets.borderColor = AnalysisLib.getChartBaseColor(this.searchResult.options.viewType, divCd);
			} else {
				datasets.backgroundColor = AnalysisLib.getChartCompareColor(this.searchResult.options.viewType, 'extra');
				datasets.pointBackgroundColor = AnalysisLib.getItemColor(this.searchResult.options.viewType, 'extra');
				datasets.borderColor = AnalysisLib.getChartCompareColor(this.searchResult.options.viewType, 'extra');
			}
		},

		addChartDatasets(header) {
			let result = {
				type: "line",
				lineTension: 0.3,
				borderWidth: 2,
				fill: true,
				label: global.xe.$t(header.divCdName),
				// pointBackgroundColor: "#fff",
				pointRadius: 4,
				showLine: true,
				interpolate: true,
				xAxisID: "x-axis-0",
				yAxisID: "Left_yAxis",
				order: 2,
				data: [],
			};

			return this.orgUnitChartOpt.data.datasets.push(result);
		},

		setChartDatasetsToData(datasets, key, divCd) {
			if (key === 'base') {
				this.searchResult.data.baseData.forEach((data) => {
					datasets.data.push(data[divCd] == "-" ? null : data[divCd]);
				});
			} else {
				this.searchResult.data.compData.forEach((data) => {
					datasets.data.push(data[divCd] == "-" ? null : data[divCd]);
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.innerBox {
	padding: 5px;
}

.mixChart {
	background-color: #ffffff;
	height: 350px;
}

.drawLine {
	background-color: #ffffff;

	height: 350px;
}

.inputGroup {
	background-color: #fff;
	display: block;
	margin: 10px 0;
	position: relative;

	label {
		padding: 12px 7%;
		font-size: 16px;
		width: 100%;
		display: block;
		text-align: left;
		color: #3c454c;
		cursor: pointer;
		position: relative;
		z-index: 2;
		transition: color 200ms ease-in;
		overflow: hidden;

		&:before {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			content: "";
			background-color: #5562eb;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%) scale3d(1, 1, 1);
			transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
			opacity: 0;
			z-index: -1;
		}

		&:after {
			width: 32px;
			height: 32px;
			content: "";
			border: 2px solid #d1d7dc;
			background-color: #fff;
			background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
			background-repeat: no-repeat;
			background-position: 2px 3px;
			border-radius: 50%;
			z-index: 2;
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			cursor: pointer;
			transition: all 200ms ease-in;
		}
	}

	input:checked~label {
		color: #fff;

		&:before {
			transform: translate(-50%, -50%) scale3d(56, 56, 1);
			opacity: 1;
		}

		&:after {
			background-color: #54e0c7;
			border-color: #54e0c7;
		}
	}

	input {
		width: 32px;
		height: 32px;
		order: 1;
		z-index: 2;
		position: absolute;
		right: 30px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
		visibility: hidden;
	}
}

// codepen formatting
.form {
	padding: 0 16px;
	max-width: 550px;
	margin: 50px auto;
	font-size: 18px;
	font-weight: 600;
	line-height: 36px;
}

body {
	background-color: #d1d7dc;
	font-family: "Fira Sans", sans-serif;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
}

code {
	background-color: #9aa3ac;
	padding: 0 8px;
}
</style>
